$violet:#7A2FFF;
$violet-5:#372753;
$gray-1:#F0F0F0;
$gray-2:#CCCCCC;
$gray-3:#F5F5F5;
$gray-4:#EFEFEF;
$gray-5:#949494;
$white:#fff;
$black:#222222;
$yellow: #FFC700 ;
$darkBlue:#4E5969;


