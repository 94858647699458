.login {
    $breakpoint1:968px;

   
    


    .right-part {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;

        .login-title {
            text-align: center;
            margin-bottom: 3em;

            & h1 {
                font-size: 2rem;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: 0.02em;
                color: $black;
            }

            & p {
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.02em;
                color: $violet;
            }
        }

        .field-form-group {
            .form-label {
                font-size: 1rem;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.02em;
                margin-bottom: 0.5em;
                color: $gray-5;
            }

            .field-email,
            .field-password {
                background-color: $gray-1;
                border: none;
               
                height: 3rem;
                border-radius: 8px;
                margin-bottom: 1em;
            }

            .input-box {
                position: relative;
                height: 52px;
              
            }

            .input-box input {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;

            }

            #toggle {
                position: absolute;
                top: 10%;
                right: 19px;
                font-size: 1.5rem;
                cursor: pointer;
                color: $gray-2;
            }
        }

        .login-btn {
            background: #7A2FFF;
            border-radius: 8px;
            color: white;
           width: 100%;
            font-size: 1.25rem;
            transition: all 0.3s ease-in-out;
            width: 100%;
        }

        .login-form {
            width: 80%;
            margin: 10%;
            max-width: 30rem;
        }
        .login-form{
            width: 80%;
            margin: 10%;
        }
    }

    .help-links {
        margin-bottom: 2rem;
    }

    .help-links .remember-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #CCCCCC;
    }

    .help-links a {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
        color: #CCCCCC;
    }
}


