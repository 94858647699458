.top-navbar{
    background-color: #4FD1C5;
    width: 100vw;  
   
}
.nav-logo{
    width: 20%;
    height: 20%;
    
}
.nav-link{
    font-size: 18px;
}
.bottom-navbar{
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: sticky;
}
.nav-icon{
    font-size: 20px;
    margin-right:12px ;
}
.nav-link:focus,.nav-icon:focus{
    color: #7A2FFF !important;
}
.disabled {
    pointer-events:none;
    opacity:0.6;
}