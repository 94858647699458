.add-project-page .add-project-form .form-control, .add-project-page .add-project-form .form-control-error {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.add-project-page .add-project-form .form-control-error {
  border-color: #FF7171;
}
.add-project-page .add-project-form .error-text {
  font-size: 0.7rem;
  color: #FF7171;
}
.add-project-page .add-project-form .form-select, .add-project-page .add-project-form .form-select-error {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.add-project-page .add-project-form .form-select-error {
  border-color: #FF7171;
}
.add-project-page .add-project-form .image-uploader-container {
  width: 100%;
  height: 400px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.add-project-page .add-project-form .image-uploader-container .text-layout {
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212529;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-project-page .add-project-form .image-uploader-container .text-layout h4 {
  color: white;
  font-size: 1rem;
}
.add-project-page .add-project-form .image-uploader-container .input-layout {
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.add-project-page .add-project-form .image-uploader-container .input-layout .image-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.add-project-page .add-project-form .image-uploader-container .img-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: scroll;
  padding: 1em;
}
.add-project-page .add-project-form .image-uploader-container .img-layout .img-container {
  width: 100%;
  height: 139px;
  border-radius: 6px;
  border: 1px solid #212529;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.add-project-page .add-project-form .image-uploader-container .img-layout .img-container .img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.add-project-page .add-project-form .image-uploader-container .img-layout .img-container .btn-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
}
.add-project-page .add-project-form .image-uploader-container .img-layout .img-container .btn-layout .delete-btn-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #212529;
  cursor: pointer;
}
.add-project-page .add-project-form .main-img-uploader {
  width: 50%;
  height: 200px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.add-project-page .add-project-form .main-img-uploader .m-text-layout {
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212529;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-project-page .add-project-form .main-img-uploader .m-text-layout h4 {
  color: white;
  font-size: 1rem;
}
.add-project-page .add-project-form .main-img-uploader .m-input-layout {
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.add-project-page .add-project-form .main-img-uploader .m-input-layout .m-image-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.add-project-page .add-project-form .main-img-uploader .m-img-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.add-project-page .add-project-form .main-img-uploader .m-img-layout .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}/*# sourceMappingURL=projectOperation.css.map */