.home {
    margin: 1em 4em;


    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: $violet-5;
    }

    .card-1 {
        background: linear-gradient(270deg, #6496DC 0%, #48C4F3 100%);
    }

    .card-2 {
        background: linear-gradient(270deg, #6F53BC 0%, #8175CB 100%);
    }

    .card-3 {
        background: linear-gradient(90deg, #EB4886 0%, #C0529F 100%);
    }

    .card-1,
    .card-2,
    .card-3 {
        padding: 1.5rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .card-container {
        padding: 2em;
        background-color: $white;
        box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        margin-bottom: 2.5rem;

        h3 {
            color: $gray-4;
            font-size: 1rem;
            font-weight: 600;
            line-height: 150%;
        }

        p {
            font-size: 2rem;
            font-weight: 700;
            line-height: 120%;
            color: $white;
        }

        img {
            width: 4rem;
        }

    }

    .chart-card,
    .pie-card {
        background-color: $white;
        box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.08);
        border-radius: 8px;

        height: 392px;
        position: relative;
    }

    #barChart,
    #pieChart {
        width: 100%;
        height: 390px;
    }

    input[type="date"] {
        background: $gray-4 url(../../Assets/icon/Vector.svg);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 90%;
        border: none;
        outline: none;
        padding: 5px;
        width: 150px;
        height: 44px;
        font-size: 18px;
        border-radius: 6px;
        position: absolute;
        top: 3px;
        right: 3px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    .barChart-title {
        position: absolute;
        top: 0;
        left: 70px;
        top: 10px;
        font-size: 1rem;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.02em;
    }

    .PieChart-title {
        font-size: 1rem;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.02em;
    }

    .pie-card {
        padding: 1em;
    }

    #pieChart {
        position: relative;
        bottom: 60px;
    }

    .total {
        text-align: center;
        position: absolute;

        top: 36%;


        .total-text {
            font-size: 0.8rem;
            color: $darkBlue;
            font-weight: 500;
            line-height: 20px;
        }

        .total-res {
            font-size: 1.5rem;
            color: $black;
            font-weight: 600;
            line-height: 40px;
        }
    }

    .separated-data-site {
        position: absolute;
        top: 80%;
        display: flex;
        left: 24%;
    }


    th:first-of-type {
        border-radius: 8px 0px 0px 8px !important;
    }

    th:last-of-type {
        border-radius: 0px 8px 8px 0px !important;
    }

    th {
        position: relative !important;
        background: white;
        bottom: 11px !important;
        border: 0 !important;
    }

    tbody {
        background-color: white !important;

    }

    .col-edit,
    .col-data-edit {
        font-size: 1rem;
        font-weight: 500;
        line-height: 23px;


        letter-spacing: 0.02em;

        color: $black;
    }

    .col-data-edit {
        display: flex;
        align-items: center;

    }

    thead tr th {
        font-size: 1rem;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.02em;
        color: $gray-5;
    }

    .checkOut {
        background-color: #FFEEED !important;
        font-size: 1rem !important;
        color: #FF3A29 !important;
        font-weight: 500 !important;

        letter-spacing: 0.02em !important;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 122px;
        height: 39px;

    }

    .checkIn {
        width: 106px;
        height: 39px;
        background-color: #E7FFF0;
        color: #00953B;
        font-size: 1rem;

        font-weight: 500;

        letter-spacing: 0.02em;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;

    }

    .table-notfi {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $gray-2;
        font-size: 1rem;
        font-family: 500;
        line-height: 23px;
        letter-spacing: 0.02em;
        margin-top: 10px;
    }

}