.add-project-page {
    .add-project-form {
        .form-control {
            display: block;
            width: 100%;
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: .375rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        }

        .form-control-error {
            @extend .form-control;
            border-color: #FF7171;
        }

        .error-text {
            font-size: .7rem;
            color: #FF7171;
        }

        .form-select {
            display: block;
            width: 100%;
            padding: .375rem 2.25rem .375rem .75rem;
            -moz-padding-start: calc(0.75rem - 3px);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
            border: 1px solid #ced4da;
            border-radius: .375rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            appearance: none;
        }

        .form-select-error {
            @extend .form-select;
            border-color: #FF7171;
        }

        .image-uploader-container {
            width: 100%;
            height: 400px;
            border: 1px solid #ced4da;
            border-radius: 10px;
            position: relative;
            z-index: 1;

            .text-layout {
                position: absolute;
                top: 90%;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #212529;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                h4 {
                    color: white;
                    font-size: 1rem;
                }
            }

            .input-layout {
                position: absolute;
                top: 90%;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;

                .image-input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .img-layout {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                overflow: scroll;
        
                padding: 1em;
       

                .img-container {
                    width: 100%;
                    height: 139px;
                    border-radius: 6px;
                    border: 1px solid #212529;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .img {
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                    }
                    .btn-layout {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 0;
                        display: flex;
                        justify-content: flex-end;
                        .delete-btn-img{
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            border: 1px solid #212529;
                            cursor: pointer;
                        }
                    }
                }

              
            }
        }

        .main-img-uploader{
            width: 50%;
            height: 200px;
            border: 1px solid #ced4da;
            border-radius: 10px;
            position: relative;
            z-index: 1;
            .m-text-layout{
                position: absolute;
                top: 85%;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #212529;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                h4 {
                    color: white;
                    font-size: 1rem;
                }
            }
            .m-input-layout{
                position: absolute;
                top: 85%;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;

                .m-image-input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            .m-img-layout{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                overflow: hidden;
                display: flex;
                justify-content: center;
                .img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
    }
}
}