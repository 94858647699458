#table_previous,
#table_next,#EmployeeALTable_previous,
#EmployeeALTable_next {
    width: 32px !important;
   
    padding: 0.1em 0.2em;

    font-size: 20px !important;
    background-color: #EDEDED !important;
    color: white !important;
    cursor: pointer !important;
    text-align: center;
    border-radius: 6px;

}


#table_paginate,#EmployeeALTable_paginate {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}
.e_table{
    justify-content: flex-end !important;
}
.length{
    width: 10%;
}

#table_paginate span .paginate_button,#EmployeeALTable_paginate span .paginate_button {
    border: 1px solid #7A2FFF;
    color: #7A2FFF;
    border-radius: 8px;
}
.page-link{
    border: 1px solid #7A2FFF !important;
    color: #7A2FFF !important;
    border-radius: 8px !important;
}

#table_paginate span .current,#EmployeeALTable_paginate span .current {
    background-color: active;
    color: white !important;
    border-radius: 8px;
    
    border: none;
}
.active{
    background-color: #4FD1C5 !important;
    color: white !important;
    border-radius: 8px !important;

    border: none !important;
}
th:first-of-type {
    border-radius: 8px 0px 0px 8px !important;
}

th:last-of-type {
    border-radius: 0px 8px 8px 0px !important;
}

th {
    position: relative !important;
    background: white;
    bottom: 11px !important;
    border: 0 !important;
    font-size: 12px !important;
}

tbody {
    background-color: white !important;

}

.col-edit,
.col-data-edit {
    font-size: 1rem;
    font-weight: 500;
    line-height: 23px;


    letter-spacing: 0.02em;

    color: $black;
}

.col-data-edit {
    display: flex;
    align-items: center;

}

thead tr th {
    font-size: 1rem;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: $gray-5;
}

.checkOut {
    background-color: #FFEEED !important;
    font-size: 1rem !important;
    color: #FF3A29 !important;
    font-weight: 500 !important;

    letter-spacing: 0.02em !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 122px;
    height: 39px;

}

.checkIn {
    width: 106px;
    height: 39px;
    background-color: #E7FFF0;
    color: #00953B;
    font-size: 1rem;

    font-weight: 500;

    letter-spacing: 0.02em;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

}

.table-notfi {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $gray-2;
    font-size: 1rem;
    font-family: 500;
    line-height: 23px;
    letter-spacing: 0.02em;
    margin-top: 10px;
}



.error{
    color: #FF3A29;
    font-size: 12px;
    margin-bottom: 5px;
    

}
.input-error{
    border: 1px solid #FF3A29 !important;
    height: 53px;
}

.cursor-not-allowed{
    cursor: not-allowed !important;
}


@keyframes load {
    0%{
        opacity: 0.08;

				filter: blur(5px);
				letter-spacing: 3px;
        }
   
}
.loadingScreen{
    background-color: #000000AA;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 140vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .animate {
        margin: auto;
        color: white;
        font-family: Helvetica, sans-serif, Arial;
        animation: load 1.2s infinite 0s ease-in-out;
        animation-direction: alternate;
        text-shadow: 0 0 1px white;
        
    }
}


.logout-btn{
    background-color: transparent;
    border: none;
    width: 100%;
    
}


.mod-bg{
    background-color: rgba(0, 0, 0, 0.299) !important;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}













#file{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#uploadBtn{
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    color: wheat;
    line-height: 30px;
    font-family: sans-serif;
    font-size: 15px;
    cursor: pointer;
    display: block;
}


.combo-checkBox{
    padding:1% 5%;
    border: 1px solid rgb(196, 196, 196);
    overflow-x: scroll;
    height: 100px;
   
    border-radius: 10px;
}

.dt-buttons{
    position: absolute;
    text-align: end;

    top: 13px;
    .dt-button {
        background-color: #7A2FFF;
        color: $white;
        border: none;
        padding: 0.75em 1em;
        border-radius: 8px;
    }
}

.btn-report{
    background-color: transparent !important;
    color: #7A2FFF !important;
   
}

.re-btn{
    background-color: transparent !important;
    color: #4FD1C5 !important;
    
    color: $white;
    border: none;
    padding: 0.5em 0.5em !important;
    border-radius: 4px !important;
  
}

.profile-pic-div{
    height: 150PX;
    width: 150PX;
    position: relative;
    border-radius: 10%;
    overflow: hidden;
    border: 1px solid rgb(62, 62, 62);
    .uploadPhoto{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }
    .upload-photo-label{
        position: absolute;
        top: 80%;
        bottom:0;
        right: 0;
        left:0;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.549);
        color: white;

    }
}

.bottom-navbar{
    position: fixed;
    top: 0;
    z-index: 1;
}
.zone-filter-select{
    width: 50%;
}

.project-photo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.image-container{
    border: 1px solid $green;
    padding: 0.5em;
    max-width: 460px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
}

.profile-pic-div_images{
    height: 150PX;
    width: 150PX;
    position: relative;
    border-radius: 7px;
    padding: 7px;
    overflow-y:scroll ;
    border: 1px solid rgb(62, 62, 62);
    .uploadPhoto{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }
    .upload-photo-label{
        position: absolute;
        top: 80%;
        bottom:0;
        right: 0;
        left:0;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.549);
        color: white;

    }
}


.card{
    padding: 30px !important;
    position: relative !important;
    width: 500px !important;
    height: 230px !important;
}
.top{
    position: absolute;
    bottom: 0;
    background: black;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    cursor: pointer;
}
.darg-area{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}
.file{
    width: 100%;
    height: 100%;
 
    opacity: 0;
}