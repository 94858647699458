


@media screen and (max-width:714px)  {
    .home{
        margin: 0;
        .chart-card{
            height: 330px;
        }
        .home-title{
            h1{
                margin: 1em 0em;
            }
        }
    }
    .card-1,.card-2{
        margin-bottom: 0.5em;
    }
    .card-table{
        overflow-x: scroll;
    }
    .rwd-m{
        margin: 0 !important;
    }


         .management{
         .rwd-icon{
             display: flex;
             align-items: center;
         }
         .tap-btn-group{
             display: none;
         }
         .btn-position-rwd{
            text-align: center !important;
            margin-top: 10px;
         }
        
     .rwd-add-btn{
         display: block;
         margin-top: 0;
     }
     .add-btn{
         display: none;
     }
   
         .stageBtn{
             padding: 5px 12px;
           
         }
      
     }
  
    
   
}
@media screen and (min-width:714px)and (max-width:768px)  {
    .home{
        margin: 0;
       
        .home-title{
            h1{
                margin: 1em 0em;
            }
        }
    }
    .card-1,.card-2{
        margin-bottom: 0.5em;
    }

    .rwd-m{
        margin: 0 !important;
    }
   
}
@media screen and (min-width:714px)and (max-width:1024px)  {
    .home{
        margin: 0;
      
        .home-title{
            h1{
                margin: 1em 0em;
            }
        }
    }
    .card-1,.card-2{
        margin-bottom: 0.5em;
    }

    .rwd-m{
        margin: 0 !important;
    }
   
}
@media screen and (min-width:1024px)and (max-width:1080px)  {
    
   
}
