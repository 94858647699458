

.login .right-part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.login .right-part .login-title {
  text-align: center;
  /* margin-bottom: 3em; */
}
.login .right-part .login-title h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #222222;
}
.login .right-part .login-title p {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #4FD1C5;
}
.login .right-part .field-form-group .form-label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 0.5em;
  color: #949494;
}
.login .right-part .field-form-group .field-email,
.login .right-part .field-form-group .field-password {
  background-color: #F0F0F0;
  border: none;
  height: 3rem;
  border-radius: 8px;
  margin-bottom: 1em;
}
.login .right-part .field-form-group .input-box {
  position: relative;
  height: 52px;
}
.login .right-part .field-form-group .input-box input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.login .right-part .field-form-group #toggle {
  position: absolute;
  top: 10%;
  right: 19px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #CCCCCC;
}
.login .right-part .login-btn {
  background: #4FD1C5;
  border-radius: 8px;
  color: white;
  width: 100%;
  font-size: 1.25rem;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.login .right-part .login-form {
  width: 80%;
  margin: 10%;
  max-width: 30rem;
}
.login .right-part .login-form {
  width: 80%;
  margin: 10%;
}
.login .help-links {
  margin-bottom: 2rem;
}
.login .help-links .remember-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #CCCCCC;
}
.login .help-links a {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #CCCCCC;
}

.home {
  margin: 1em 4em;
}
.home h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #372753;
}
.home .card-1 {
  background: linear-gradient(270deg, #6496DC 0%, #48C4F3 100%);
}
.home .card-2 {
  background: linear-gradient(270deg, #6F53BC 0%, #8175CB 100%);
}
.home .card-3 {
  background: linear-gradient(90deg, #EB4886 0%, #C0529F 100%);
}
.home .card-1,
.home .card-2,
.home .card-3 {
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home .card-container {
  padding: 2em;
  background-color: #fff;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 2.5rem;
}
.home .card-container h3 {
  color: #EFEFEF;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}
.home .card-container p {
  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  color: #fff;
}
.home .card-container img {
  width: 4rem;
}


.home input[type=date] {
  background: #EFEFEF url(../../Assets/icon/Vector.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 90%;
  border: none;
  outline: none;
  padding: 5px;
  width: 150px;
  height: 44px;
  font-size: 18px;
  border-radius: 6px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.home input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
}




.home .total {
  text-align: center;
  position: absolute;
  top: 36%;
}
.home .total .total-text {
  font-size: 0.8rem;
  color: #4E5969;
  font-weight: 500;
  line-height: 20px;
}
.home .total .total-res {
  font-size: 1.5rem;
  color: #222222;
  font-weight: 600;
  line-height: 40px;
}
.home .separated-data-site {
  position: absolute;
  top: 80%;
  display: flex;
  left: 24%;
}
.home th:first-of-type {
  border-radius: 8px 0px 0px 8px !important;
}
.home th:last-of-type {
  border-radius: 0px 8px 8px 0px !important;
}
.home th {
  position: relative !important;
  background: white;
  bottom: 11px !important;
  border: 0 !important;
}
.home tbody {
  background-color: white !important;
}
.home .col-edit,
.home .col-data-edit {
  font-size: 1rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #222222;
}
.home .col-data-edit {
  display: flex;
  align-items: center;
}
.home thead tr th {
  font-size: 1rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #949494;
}


.home .table-notfi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #CCCCCC;
  font-size: 1rem;
  font-family: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-top: 10px;
}

.management {
  margin: 1em 4em;
}
.management h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #372753;
}
.management .addBtn {
  background-color: #4FD1C5;
  color: #fff;
  border: none;
  padding: 0.75em 1em;
  border-radius: 50px;
}
.management .addIcon {
  color: white;
}
.management .deleteBtn {
  background-color: transparent;
  border: 0;
  font-size: 20px;
  padding: 0;
  color: #EB5757;
  display: flex;
  align-items: center;
}
.management .viewBtn {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  padding: 0;
  display: flex;
  color: #2559A7;
  align-items: center;
}
.management .work {
  background-color: #00D93D;
  width: 127px;
  height: 42px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 23px;
  color: #FFFFFF;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
}


.title-add-madal {
  text-align: center;
  width: 100%;
  font-size: 2rem;
  color: #222222;
  margin-top: 0.5em;
  line-height: 30px;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: 1.5em;
}

.field-group {
  width: 100;
}
.field-group .Model-Number,
.field-group .Zone {
  border: 1px solid #E9E9E9;
  background-color: transparent;
  width: 405px;
  height: 40px;
  margin-bottom: 1rem;
  border-radius: 6px;
}
.field-group ,
.field-group .ModelNumber-label {
  font-size: 1rem;
  line-height: 23px;
  margin-bottom: 8px;
  letter-spacing: 0.02em;
  color: black;
  font-weight: 500;
}

.tab-content .active {
  background-color: transparent !important;
}

.add-modal-btn {
  width: 127px;
  height: 41px;
  background: #4FD1C5;
  border-radius: 8px;
  color: white;
  border: none;
  text-align: center;
}

.stageBtn {
  background-color: #EEEEEE;
  border: none;
  color: #949494;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 50px;
}

.active {
  background-color: #4FD1C5;
  padding: 10px 24px;
  border-radius: 50px;
  font-size: 16px;
}

.rwd-icon {
  display: none;
}

.rwd-add-btn {
  display: none;
}

.disabled-btn {
  width: 127px;
  height: 41px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.493);
  border: none;
  text-align: center;
  cursor: not-allowed;
  background: #547774;
}

.transation {
  transition: all 0.1s ease-in-out;
}




@media screen and (max-width: 714px) {
  .home {
    margin: 0;
  }

  .home .home-title h1 {
    margin: 1em 0em;
  }
  .card-1, .card-2 {
    margin-bottom: 0.5em;
  }
  .card-table {
    overflow-x: scroll;
  }
  .rwd-m {
    margin: 0 !important;
  }
  .management .rwd-icon {
    display: flex;
    align-items: center;
  }
  .management .tap-btn-group {
    display: none;
  }
  .management .btn-position-rwd {
    text-align: center !important;
    margin-top: 10px;
  }
  .management .rwd-add-btn {
    display: block;
    margin-top: 0;
  }
  .management .add-btn {
    display: none;
  }
  .management .stageBtn {
    padding: 5px 12px;
  }
}
@media screen and (min-width: 714px) and (max-width: 768px) {
  .home {
    margin: 0;
  }

  .home .home-title h1 {
    margin: 1em 0em;
  }
  .card-1, .card-2 {
    margin-bottom: 0.5em;
  }
  .rwd-m {
    margin: 0 !important;
  }
}
@media screen and (min-width: 714px) and (max-width: 1024px) {
  .home {
    margin: 0;
  }
 
  .home .home-title h1 {
    margin: 1em 0em;
  }
  .card-1, .card-2 {
    margin-bottom: 0.5em;
  }
  .rwd-m {
    margin: 0 !important;
  }
}
#table_previous,
#table_next, #EmployeeALTable_previous,
#EmployeeALTable_next {
  width: 32px !important;
  padding: 0.1em 0.2em;
  font-size: 20px !important;
  background-color: #EDEDED !important;
  color: white !important;
  cursor: pointer !important;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
}

#table_paginate, #EmployeeALTable_paginate {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  
}

.e_table {
  justify-content: flex-end !important;
}

.length {
  width: 10%;
}

#table_paginate span .paginate_button, #EmployeeALTable_paginate span .paginate_button {
  border: 1px solid #4FD1C5;
  color: #4FD1C5;
  border-radius: 50%;
}

.page-link {
  border: 1px solid #7A2FFF !important;
  color: #7A2FFF !important;
  border-radius: 8px !important;
}

#table_paginate span .current, #EmployeeALTable_paginate span .current {
  background-color: #4FD1C5;
  color: white !important;
  border-radius: 50px;
  border: none;
}

.active {
  background-color: #4FD1C5 !important;
  color: white !important;
  border-radius: 50px !important;
  border: none !important;
}

th:first-of-type {
  border-radius: 8px 0px 0px 8px !important;
}

th:last-of-type {
  border-radius: 0px 8px 8px 0px !important;
}

th {
  position: relative !important;
  background: white;
  bottom: 11px !important;
  border: 0 !important;
  font-size: 12px !important;
}

tbody {
  background-color: white !important;
}

.col-edit,
.col-data-edit {
  font-size: 1rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #222222;
}

.col-data-edit {
  display: flex;
  align-items: center;
}

thead tr th {
  font-size: 1rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #949494;
}

.checkOut {
  background-color: #FFEEED !important;
  font-size: 1rem !important;
  color: #FF3A29 !important;
  font-weight: 500 !important;
  letter-spacing: 0.02em !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 122px;
  height: 39px;
}

.checkIn {
  width: 106px;
  height: 39px;
  background-color: #E7FFF0;
  color: #00953B;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.table-notfi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #CCCCCC;
  font-size: 1rem;
  font-family: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-top: 10px;
}

.error {
  color: #FF3A29;
  font-size: 12px;
  margin-bottom: 5px;
}

.input-error {
  border: 1px solid #FF3A29 !important;
  height: 53px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

@keyframes load {
  0% {
    opacity: 0.08;
    filter: blur(5px);
    letter-spacing: 3px;
  }
}
.loadingScreen {
  background-color: rgba(0, 0, 0, 0.6666666667);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.loadingScreen .animate {
  margin: auto;
  color: white;
  font-family: Helvetica, sans-serif, Arial;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px white;
}

.logout-btn {
  background-color: transparent;
  border: none;
  width: 100%;
}

.mod-bg {
  background-color: rgba(0, 0, 0, 0.299) !important;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#file {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#uploadBtn {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: wheat;
  line-height: 30px;
  font-family: sans-serif;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.combo-checkBox {
  padding: 1% 5%;
  border: 1px solid rgb(196, 196, 196);
  overflow-x: scroll;
  height: 100px;
  border-radius: 10px;
}

.dt-buttons {
  position: absolute;
  text-align: end;
  top: 13px;
}
.dt-buttons .dt-button {
  background-color: #7A2FFF;
  color: #fff;
  border: none;
  padding: 0.75em 1em;
  border-radius: 8px;
}

.btn-report {
  background-color: transparent !important;
  color: #7A2FFF !important;
}

.re-btn {
  background-color: transparent !important;
  color: #4FD1C5 !important;
  color: #fff;
  border: none;
  padding: 0.5em 0.5em !important;
  border-radius: 4px !important;
}

.profile-pic-div {
  height: 150PX;
  width: 150PX;
  position: relative;
  border-radius: 10%;
  overflow: hidden;
  border: 1px solid rgb(62, 62, 62);
}
.profile-pic-div .uploadPhoto {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.profile-pic-div .upload-photo-label {
  position: absolute;
  top: 80%;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.549);
  color: white;
}

.bottom-navbar {
  position: fixed;
  top: 0;
  z-index: 1;
}

.zone-filter-select {
  width: 50%;
}

.project-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

body {
  overflow-x: hidden;
  background-color: #F5F5F5;
}
.card{
  padding: 30px !important;
  position: relative !important;
  width: 500px !important;
  height: 230px !important;
}
.top{
  position: absolute;
  bottom: 0;
  background: black;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  cursor: pointer;
}
.darg-area{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}
.file{
  width: 100%;
  height: 100%;

  opacity: 0;
}
html {
  font-size: 16px;
}/*# sourceMappingURL=main.css.map */