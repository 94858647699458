.management {
    margin: 1em 4em;

    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: $violet-5;
    }

    .addBtn {
        background-color: #4FD1C5;
        color: $white;
        border: none;
        padding: 0.75em 1em;
        border-radius: 8px;

    }

    .addIcon {
        color: white;
    }
 
   
    
    .deleteBtn {
        background-color: transparent;
        border: 0;
        font-size: 20px;
        padding: 0;
        color: #EB5757;
        display: flex;
        align-items: center;

    }
    .viewBtn{
        background-color: transparent;
        border: 0;
        font-size: 16px;
        padding: 0;
        display: flex;
        color: #2559A7;
        align-items: center;
    }



    .work {
        background-color: #00D93D;
        width: 127px;
        height: 42px;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 23px;
        color: #FFFFFF;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shutDown {
        background-color: #D4D4D4;
        width: 127px;
        height: 42px;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 23px;
        color: #FFFFFF;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        justify-content: center;
    }



}

.title-add-madal {
    text-align: center;
    width: 100%;
    font-size: 2rem;
    color: $black;
    margin-top: 0.5em;
    line-height: 30px;
    letter-spacing: 0.02em;
    font-weight: 600;
    margin-bottom: 1.5em;
}

.field-group {
    width: 100;

    .Model-Number,
    .Zone {
        border: 1px solid #E9E9E9;
        background-color: transparent;
        width: 405px;
        height: 40px;
        margin-bottom: 1rem;
        border-radius: 6px;
    }

   
    .ModelNumber-label {
        font-size: 1rem;
        line-height: 23px;
        margin-bottom: 8px;
        letter-spacing: 0.02em;
        color: #CCCCCC;
        font-weight: 500;
    }

}

.tab-content .active{
    background-color: transparent !important;
}
.add-modal-btn {
        width: 127px ;
        height: 41px ;
        background: #4FD1C5 ;
        border-radius: 8px ;
        color: white ;
        border: none ;
        text-align: center ;
    }

    


    .stageBtn{
        background-color: #EEEEEE;
        border: none;
        color: #949494;
        padding: 10px 24px;
        font-size: 16px ;
    }
    .active{
        background-color: #4FD1C5 ;
        padding: 10px 24px;
        border-radius: 8px ;
        font-size: 16px ;
    }


    .rwd-icon{
        display: none;
    }

    .rwd-add-btn{
        display: none;
    }
    .disabled-btn{
        width: 127px ;
        height: 41px ;
        border-radius: 8px ;
        color: rgba(255, 255, 255, 0.493) ;
        border: none ;
        text-align: center ;
        cursor: not-allowed;
        background: #431a88 ;
    }

    .transation{
        transition: all 0.1s ease-in-out;
    }





   